.gea-datepicker-container {
  width: 296px;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  @include invalid-input();
}

.p-datepicker {
  padding: $calendarPadding;
  background: $calendarInlineBg;
  color: $calendarTextColor;
  border: $calendarBorder;
  border-radius: $borderRadius;
  width: 296px;

  &:not(.p-datepicker-inline) {
    background: $calendarBg;
    border: $calendarOverlayBorder;
    box-shadow: $inputOverlayShadow;

    .p-datepicker-header {
      background: $calendarHeaderBg;
    }
  }

  .p-datepicker-header {
    padding: $calendarHeaderPadding;
    color: $calendarHeaderTextColor;
    background: $calendarInlineHeaderBg;
    font-weight: $calendarHeaderFontWeight;
    margin: $inputListHeaderMargin;
    border-bottom: $calendarHeaderBorder;
    border-top-right-radius: $borderRadius;
    border-top-left-radius: $borderRadius;

    .p-datepicker-prev,
    .p-datepicker-next {
      @include action-icon();
    }

    .p-datepicker-title {
      line-height: $actionIconHeight;

      .p-datepicker-year,
      .p-datepicker-month {
        color: $calendarHeaderTextColor;
        transition: $actionIconTransition;
        //font-weight: $calendarHeaderFontWeight;
        padding: $calendarHeaderCellPadding;
        @include gea-font-heading(14);

        &:enabled:hover {
          color: $calendarMonthYearHeaderHoverTextColor;
        }
      }

      .p-datepicker-month {
        margin-right: $inlineSpacing;
      }
    }
  }

  .p-datepicker-calendar-container {
    padding: 6px 0 6px;
    background-color: $geaui-grey-05;
  }
  table {
    font-size: $fontSize;
    margin: 0;

    th {
      @include gea-font-heading(14);
      padding: $calendarHeaderCellPadding;

      > span {
        width: $calendarCellDateWidth;
        height: $calendarCellDateHeight;
      }
    }

    td {
      @include gea-font-description(12);
      padding: $calendarCellDatePadding;

      > span {
        width: $calendarCellDateWidth;
        height: $calendarCellDateHeight;
        border-radius: $calendarCellDateBorderRadius;
        transition: $listItemTransition;
        border: $calendarCellDateBorder;

        &.p-highlight {
          color: $highlightTextColor;
          background: $highlightBg;
        }

        &:focus {
          @include focused();
        }
      }

      &.p-datepicker-today {
        > span {
          background: $calendarCellDateTodayBg;
          color: $calendarCellDateTodayTextColor;
          border-color: $calendarCellDateTodayBorderColor;

          &.p-highlight {
            color: $highlightTextColor;
            background: $highlightBg;
          }
        }
      }
      &.p-datepicker-other-month {
        color: $geaui-grey-02;
      }
    }
  }

  .p-datepicker-buttonbar {
    padding: $calendarButtonBarPadding;
    border-top: $divider;

    .p-button {
      width: auto;
    }
  }

  .p-timepicker {
    border-top: $divider;
    padding: $calendarTimePickerPadding;

    button {
      @include action-icon();

      &:last-child {
        margin-top: .2em;
      }
    }

    span {
      font-size: $calendarTimePickerTimeFontSize;
    }

    > div {
      padding: $calendarTimePickerElementPadding;
    }
  }

  &.p-datepicker-timeonly {
    .p-timepicker {
      border-top: 0 none;
    }
  }

  .p-monthpicker {
    margin: $calendarTableMargin;

    .p-monthpicker-month {
      padding: $calendarCellDatePadding;
      transition: $listItemTransition;
      border-radius: $borderRadius;

      &.p-highlight {
        color: $highlightTextColor;
        background: $highlightBg;
      }
    }
  }

  .p-yearpicker {
    margin: $calendarTableMargin;

    .p-yearpicker-year {
      padding: $calendarCellDatePadding;
      transition: $listItemTransition;
      border-radius: $borderRadius;

      &.p-highlight {
        color: $highlightTextColor;
        background: $highlightBg;
      }
    }
  }

  &.p-datepicker-multiple-month {
    .p-datepicker-group {
      border-left: $divider;
      padding-right: $calendarPadding;
      padding-left: $calendarPadding;
      padding-top: 0;
      padding-bottom: 0;
      background-color: $geaui-grey-05;

      &:first-child {
        padding-left: 0;
        border-left: 0 none;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &:not(.p-disabled) {
    table {
      td {
        span:not(.p-highlight):not(.p-disabled) {
          &:hover {
            background: $calendarCellDateHoverBg;
          }

          &:focus {
            @include focused();
          }
        }
      }
    }

    .p-monthpicker {
      .p-monthpicker-month {
        &:not(.p-disabled) {
          &:not(.p-highlight):hover {
            background: $calendarCellDateHoverBg;
          }

          &:focus {
            @include focused();
          }
        }
      }
    }

    .p-yearpicker {
      .p-yearpicker-year {
        &:not(.p-disabled) {
          &:not(.p-highlight):hover {
            background: $calendarCellDateHoverBg;
          }

          &:focus {
            @include focused();
          }
        }
      }
    }
  }

}

// no hover effects
p-calendar .p-inputtext:enabled:hover {
  border-color: $geaui-grey-03;
}

p-calendar.p-calendar-clearable {
  .p-inputtext {
    padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
  }

  .p-calendar-clear-icon {
    color: $inputIconColor;
    right: nth($inputPadding, 2);
  }
}

p-calendar.p-calendar-clearable.p-calendar-w-btn {
  .p-calendar-clear-icon {
    color: $inputIconColor;
    right: $buttonIconOnlyWidth + nth($inputPadding, 2);
  }
}

@media screen and (max-width: $calendarBreakpoint) {
  .p-datepicker {
    table {
      th, td {
        padding: $calendarCellDatePaddingSM;
      }
    }
  }
}

// borders
// $geaui-grey-03: #DCDCDC; selected
// $geaui-grey-02: #C8C8C8; default
// $geaui-focused-outline: #000f41 focussed
// hover no change

// calendar icon
.p-calendar input.p-inputtext {
  flex: 2 0 auto;
  width: 296px;
}
.p-calendar-w-btn .p-datepicker-trigger {
  border: 0;
  background: transparent;
  right: 0;
  position: absolute;
  height: 48px;
  &.p-ripple {
    transition: none; // no ripple effect
  }
  &:enabled:hover, &:enabled:active {
    background: transparent;
    border: 2px solid transparent;
  }
}
.p-focus {
  .p-inputtext:enabled:focus, .p-inputtext:focus {
    border: 2px solid $geaui-focused-outline;
  }
}

// footer
.gea-datepicker-footer-container {
  padding: 10px 20px;
  display: flex;
  gap: 10px;
  >:first-child {
    flex: 1;
  }
}
