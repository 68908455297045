.p-inputtextarea-resizable {
  overflow: hidden;
  resize: none;
}

.p-fluid .p-inputtextarea {
  width: 100%;
  background-color: red;
}

.gea-textfield-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 25px;
  height: 100%;

  .p-float-label > label {
    height: 20px;
  }
}

.p-float-label textarea ~ label {
  top: 22px !important;
}

.gea-textfield {
  height: 160px;
  overflow: unset;
  white-space: pre-wrap;
  text-overflow: unset;

  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
}
