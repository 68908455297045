@import "primeng/resources/primeng.css";

.p-inputtext {
  background: $inputBg;
  padding: 12px;
  border: 3px solid $geaui-grey-03;
  transition: $formElementTransition;
  appearance: none;
  border-radius: $borderRadius;
  width: 100%;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;

  &.has-suffix-icon {
    padding-right: 40px;
  }

  &.gea-input-field {
    color: $geaui-primary-black;
  }

  &:enabled:hover {
    //border-color: $inputHoverBorderColor;
  }

  &:enabled:focus,&:focus {
    //@include focused-input();
    border: 3px solid $geaui-focused-outline;
    box-shadow: none;
  }

  &.ng-dirty.ng-invalid {
    //@include invalid-input();
    border: 3px solid $geaui-tertiary-red-60;
  }

  &.p-inputtext-sm {
    @include scaledFontSize($inputTextFontSize, $scaleSM);
    @include scaledPadding($inputPadding, $scaleSM);
  }

  &.p-inputtext-lg {
    @include scaledFontSize($inputTextFontSize, $scaleLG);
    @include scaledPadding($inputPadding, $scaleLG);
  }
}

.p-float-label > label {
  left: nth($inputPadding, 2);
  color: $geaui-pure-black;
  transition-duration: $transitionDuration;
  padding-left: 10px;
  width : 90%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: $geaui-tertiary-red-80;
}

.p-input-icon-left > .p-icon-wrapper.p-icon,
.p-input-icon-left > i:first-of-type {
  left: nth($inputPadding, 2);
  color: $inputIconColor;
}

.p-input-icon-left > .p-inputtext {
  padding-left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

.p-input-icon-left.p-float-label > label {
  left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

.p-input-icon-right > .p-icon-wrapper,
.p-input-icon-right > i:last-of-type {
  right: nth($inputPadding, 2);
  color: $inputIconColor;
}

.p-input-icon-right > .p-inputtext {
  padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

@include placeholder {
  color: $inputPlaceholderTextColor;
}

.p-input-filled {
  .p-inputtext {
    background-color: $inputFilledBg;

    &:enabled:hover {
      background-color: $inputFilledHoverBg;
    }

    &:enabled:focus {
      background-color: $inputFilledFocusBg;
    }
  }
}

.p-inputtext-sm {
  .p-inputtext {
    @include scaledFontSize($inputTextFontSize, $scaleSM);
    @include scaledPadding($inputPadding, $scaleSM);
  }
}

.p-inputtext-lg {
  .p-inputtext {
    @include scaledFontSize($inputTextFontSize, $scaleLG);
    @include scaledPadding($inputPadding, $scaleLG);
  }
}

/*   Custom Css Styles    */


.gea-input-container > .p-float-label > .gea-pressed  {
  border-color: $geaui-primary !important;
}

.gea-input-container {
  .custom-disable > input {
    &:hover {
      cursor: default;
    }
  }
}

.p-float-label > .gea-input-field:focus ~ label ,
.p-float-label > .gea-input-field.p-filled ~ label {
  top: -14px;
  left: -8px;
  font-size: 14px;
}

.gea-always-float-label ~ label {
  top: -14px;
  left: -8px;
  font-size: 14px;
}

.gea-custom-disable > input {
  border-color: $geaui-grey-05;
  color: $geaui-grey-01 !important;
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: 400;
  outline: none;

  &.gea-input-field {
    color: $geaui-grey-01;
  }

  &:hover{
    border-color: $geaui-grey-05 !important;
  }

  &:focus {
    border-color: $geaui-grey-05 !important;
  }

  ~ label {
    position: relative;
    color: $geaui-grey-01;
    top: -34px;
    left: 22px;
  }

  ~ .gea-floating {
      font-size: 14px;
      top: -74px;
      left: 2px;
  }
}

.gea-custom-disable > label {
  color: $geaui-grey-01;
  opacity: 1;
}

.gea-error-input {
  border: 3px solid $geaui-tertiary-red-60;

  ~ label {
    color: $geaui-tertiary-red-80;
  }
}


.gea-error-container-input  {
  position: relative;
  display: flex;

  > p {
    position: absolute;
    top: 8px;
    left: 26px;
    font-size: 14px;
    margin: 0;
    color: $geaui-tertiary-red-80;
  }

  > .gea-error-icon-position {
      position: absolute;
      top: 8px;
      left: 1px;
  }
}

.gea-helpText-container-input {
  position: relative;
  display: flex;

  > p {
    position: absolute;
    top: 8px;
    left: 5px;
    font-size: 11px;
    margin: 0;
  }
}

.gea-suffix-icon-16 {
  position: absolute;
  right: 14px;
  top: 15px;
}

.gea-suffix-icon-24 {
  position: absolute;
  right: 14px;
  top: 10px;
}

.gea-suffix-icon-32 {
  position: absolute;
  right: 14px;
  top: 8px;
}

.gea-input-container {
  geaui-icon-v2:hover {
    cursor: pointer;
  }
}