@use 'sass:math';

.with-icon {
  .p-button {
    padding: 7px 18px;

    &.p-button-outlined-link {
      padding: 10px 22px;
    }
  }
}

.p-button-secondary.with-icon .p-button {
  padding: 5px 17px;
}

.p-button-content-bold {
  display: contents;
  font-weight: 600;
}

.p-button-content-normal {
  display: contents;
}

.button-loading-spinner {
  height: 24px;

  &.p-progress-spinner {
    top: 2px;
    height: 20px;
    width: 24px;
  }

  circle {
    height: 16px;
    width: 16px;
    r: 16px;
    stroke: $geaui-grey-01;
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
  }
}

.max-width .p-button {
  width: 100%;
  display: flex;

  .space-maximizer {
    flex: 1;
  }
}

.p-button {
  @include gea-font-button(16, 500); // small buttons are not yet implemented
  color: $buttonTextColor;
  background: $buttonBg;
  border: $buttonBorder;
  padding: $buttonPadding;
  transition: $formElementTransition;
  border-radius: $borderRadius;
  gap: 8px;
  height: 40px;
  max-height: 40px;
  white-space: nowrap;

  &.p-button-sm {
    @include gea-font-button(14, 600);
    color: $buttonTextColor;
    background: $buttonBg;
    border: $buttonBorder;
    padding: $buttonSmallPadding;
    transition: $formElementTransition;
    border-radius: $borderRadius;
    gap: 8px;
    height: 32px;
    max-height: 32px;
    white-space: nowrap;
  }

  &:disabled {
    background: $geaui-grey-04;
    border-color: $geaui-grey-04;
    color: $geaui-grey-01;
    opacity: 1;
  }

  &:enabled:hover {
    background: $buttonHoverBg;
    color: $buttonTextHoverColor;
    border-color: $buttonHoverBorderColor;
  }

  &:enabled:active {
    background: $buttonActiveBg;
    color: $buttonTextActiveColor;
    border-color: $buttonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: $buttonBg;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      background: rgba($buttonBg, $textButtonHoverBgOpacity);
      color: $buttonBg;
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      background: rgba($buttonBg, $textButtonActiveBgOpacity);
      color: $buttonBg;
      border: $outlinedButtonBorder;
    }

    &.p-button-plain {
      color: $plainButtonTextColor;
      border-color: $plainButtonTextColor;

      &:enabled:hover {
        background: $plainButtonHoverBgColor;
        color: $plainButtonTextColor;
      }

      &:enabled:active {
        background: $plainButtonActiveBgColor;
        color: $plainButtonTextColor;
      }
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: $buttonBg;
    border-color: transparent;

    &:disabled {
      color: $geaui-grey-01;
    }

    &:enabled:hover {
      //background: rgba($buttonBg, $textButtonHoverBgOpacity);
      background: $geaui-secondary-hover;
      color: $buttonBg;
      border-color: transparent;
    }

    &:enabled:active {
      background: $geaui-secondary-pressed;
      color: $buttonBg;
      border-color: transparent;
    }

    &.p-button-plain {
      color: $plainButtonTextColor;

      &:enabled:hover {
        background: $plainButtonHoverBgColor;
        color: $plainButtonTextColor;
      }

      &:enabled:active {
        background: $plainButtonActiveBgColor;
        color: $plainButtonTextColor;
      }
    }
  }

  //&:focus {
  //  @include focused();
  //}

  .p-button-label {
    transition-duration: $transitionDuration;
  }

  .p-button-icon-left {
    margin-right: $inlineSpacing;
  }

  .p-button-icon-right {
    margin-left: $inlineSpacing;
  }

  .p-button-icon-bottom {
    margin-top: $inlineSpacing;
  }

  .p-button-icon-top {
    margin-bottom: $inlineSpacing;
  }

  .p-badge {
    margin-left: $inlineSpacing;
    min-width: $fontSize;
    height: $fontSize;
    line-height: $fontSize;
    color: $buttonBg;
    background-color: $buttonTextColor;
  }

  &.p-button-raised {
    box-shadow: $raisedButtonShadow;
  }

  &.p-button-rounded {
    border-radius: $roundedButtonBorderRadius;
  }

  &.p-button-icon-only {
    width: $buttonIconOnlyWidth;
    padding: $buttonIconOnlyPadding;

    .p-button-icon-left,
    .p-button-icon-right {
      margin: 0;
    }

    &.p-button-rounded {
      border-radius: 50%;
      height: $buttonIconOnlyWidth;
      max-height: $buttonIconOnlyWidth;
    }
  }

  &.p-button-sm {
    @include scaledFontSize($fontSize, $scaleSM);
    @include scaledPadding($buttonPadding, $scaleSM);

    .p-button-icon {
      @include scaledFontSize($primeIconFontSize, $scaleSM);
    }
  }

  &.p-button-lg {
    @include scaledFontSize($fontSize, $scaleLG);
    @include scaledPadding($buttonPadding, $scaleLG);

    .p-button-icon {
      @include scaledFontSize($primeIconFontSize, $scaleLG);
    }
  }

  &.p-button-loading-label-only {
    .p-button-label {
      margin-left: $inlineSpacing;
    }

    .p-button-loading-icon {
      margin-right: 0;
    }
  }
}

.p-fluid {
  .p-button {
    width: 100%;
  }

  .p-button-icon-only {
    width: $buttonIconOnlyWidth;
  }

  .p-buttonset {
    display: flex;

    .p-button {
      flex: 1;
    }
  }
}

// outlined-link
.p-button.p-button-outlined.p-button-outlined-link {
  color: $geaui-primary-black;
  border: 2px solid $geaui-grey-02;
  height: 48px;
  max-height: 48px;

  .p-button-content {
    font-weight: 500;
  }

  &:enabled:hover {
    color: $geaui-primary-black;
    background: $geaui-secondary-hover;
    border: 2px solid $geaui-grey-02;
  }

  &:disabled {
    color: $geaui-grey-01;
    border-color: $geaui-grey-03;
    background: $geaui-grey-04;
  }

  &:enabled:active {
    background: $geaui-secondary-pressed;
  }

  &:focus,
  &:focus-visible {
    border-radius: initial;
    border-color: $geaui-focused-outline;
  }
}

// inverted
.gea-button-inverted .p-button.p-button-secondary {
  background: none;
  border: 2px solid $geaui-pure-white;
  color: $geaui-pure-white;

  &:enabled:hover {
    background: none;
    color: $secondaryButtonTextHoverColor;
    border-color: $secondaryButtonHoverBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: $secondaryButtonBg;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
      color: $secondaryButtonBg;
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
      color: $secondaryButtonBg;
      border: $outlinedButtonBorder;
    }
  }
}

// secondary and tertiary
.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button,
.p-button.p-button-secondary-transparent,
.p-buttonset.p-button-secondary-transparent > .p-button,
.p-splitbutton.p-button-secondary-transparent > .p-button,
.p-button.p-button-tertiary,
.p-buttonset.p-button-tertiary > .p-button,
.p-splitbutton.p-button-tertiary > .p-button {
  color: $secondaryButtonTextColor;
  background: $secondaryButtonBg;
  border: $secondaryButtonBorder;
  height: 40px;
  max-height: 40px;
  padding: 5px 17px;

  &.p-button-secondary-transparent{
    background: none;
  }

  &:disabled {
    border-color: $geaui-grey-04;
    color: $geaui-grey-01;
    opacity: 1;
  }

  &:enabled:hover {
    background: $secondaryButtonHoverBg;
    color: $secondaryButtonTextHoverColor;
    border-color: $secondaryButtonHoverBorderColor;
  }

  //&:enabled:focus {
  //  box-shadow: $secondaryButtonFocusShadow;
  //}

  &:enabled:active {
    background: $secondaryButtonActiveBg;
    color: $secondaryButtonTextActiveColor;
    border-color: $secondaryButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: $secondaryButtonBg;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
      color: $secondaryButtonBg;
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
      color: $secondaryButtonBg;
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: $secondaryButtonBg;
    border-color: transparent;

    &:enabled:hover {
      background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
      color: $secondaryButtonBg;
    }

    &:enabled:active {
      background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
      color: $secondaryButtonBg;
    }
  }
}

// red cancel button
.p-button.p-button-cancel-red,
.p-buttonset.p-button-cancel-red > .p-button,
.p-splitbutton.p-button-cancel-red > .p-button {
  color: $redCancelButtonTextColor;
  background: $redCancelButtonBg;
  border: $redCancelButtonBorder;
  height: 38px;
  padding: 5px 17px;

  &:disabled {
    border-color: $geaui-grey-04;
    color: $geaui-grey-01;
    opacity: 1;
  }
  &:enabled:hover {
    background: $redCancelButtonHoverBg;
    color: $redCancelButtonTextHoverColor;
    border-color: $redCancelButtonHoverBorderColor;
  }

  &:enabled:active {
    background: $redCancelButtonActiveBg;
    color: $redCancelButtonTextActiveColor;
    border-color: $redCancelButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: $redCancelButtonBg;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      background: rgba($redCancelButtonBg, $textButtonHoverBgOpacity);
      color: $redCancelButtonBg;
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      background: rgba($redCancelButtonBg, $textButtonActiveBgOpacity);
      color: $redCancelButtonBg;
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: $redCancelButtonBg;
    border-color: transparent;

    &:enabled:hover {
      background: rgba($redCancelButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
      color: $redCancelButtonBg;
    }

    &:enabled:active {
      background: rgba($redCancelButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
      color: $redCancelButtonBg;
    }
  }
}

// tertiary
.p-button.p-button-tertiary,
.p-buttonset.p-button-tertiary > .p-button,
.p-splitbutton.p-button-tertiary > .p-button {
  border: 0;
  background: transparent;
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  color: $infoButtonTextColor;
  background: $infoButtonBg;
  border: $infoButtonBorder;

  &:enabled:hover {
    background: $infoButtonHoverBg;
    color: $infoButtonTextHoverColor;
    border-color: $infoButtonHoverBorderColor;
  }

  //&:enabled:focus {
  //  box-shadow: $infoButtonFocusShadow;
  //}

  &:enabled:active {
    background: $infoButtonActiveBg;
    color: $infoButtonTextActiveColor;
    border-color: $infoButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: $infoButtonBg;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
      color: $infoButtonBg;
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
      color: $infoButtonBg;
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: $infoButtonBg;
    border-color: transparent;

    &:enabled:hover {
      background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
      color: $infoButtonBg;
    }

    &:enabled:active {
      background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
      color: $infoButtonBg;
    }
  }
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: $successButtonTextColor;
  background: $successButtonBg;
  border: $successButtonBorder;

  &:enabled:hover {
    background: $successButtonHoverBg;
    color: $successButtonTextHoverColor;
    border-color: $successButtonHoverBorderColor;
  }

  //&:enabled:focus {
  //  box-shadow: $successButtonFocusShadow;
  //}

  &:enabled:active {
    background: $successButtonActiveBg;
    color: $successButtonTextActiveColor;
    border-color: $successButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: $successButtonBg;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      background: rgba($successButtonBg, $textButtonHoverBgOpacity);
      color: $successButtonBg;
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      background: rgba($successButtonBg, $textButtonActiveBgOpacity);
      color: $successButtonBg;
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: $successButtonBg;
    border-color: transparent;

    &:enabled:hover {
      background: rgba($successButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
      color: $successButtonBg;
    }

    &:enabled:active {
      background: rgba($successButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
      color: $successButtonBg;
    }
  }
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: $warningButtonTextColor;
  background: $warningButtonBg;
  border: $warningButtonBorder;

  &:enabled:hover {
    background: $warningButtonHoverBg;
    color: $warningButtonTextHoverColor;
    border-color: $warningButtonHoverBorderColor;
  }

  //&:enabled:focus {
  //  box-shadow: $warningButtonFocusShadow;
  //}

  &:enabled:active {
    background: $warningButtonActiveBg;
    color: $warningButtonTextActiveColor;
    border-color: $warningButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: $warningButtonBg;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
      color: $warningButtonBg;
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
      color: $warningButtonBg;
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: $warningButtonBg;
    border-color: transparent;

    &:enabled:hover {
      background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
      color: $warningButtonBg;
    }

    &:enabled:active {
      background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
      color: $warningButtonBg;
    }
  }
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  color: $helpButtonTextColor;
  background: $helpButtonBg;
  border: $helpButtonBorder;

  &:enabled:hover {
    background: $helpButtonHoverBg;
    color: $helpButtonTextHoverColor;
    border-color: $helpButtonHoverBorderColor;
  }

  //&:enabled:focus {
  //  box-shadow: $helpButtonFocusShadow;
  //}

  &:enabled:active {
    background: $helpButtonActiveBg;
    color: $helpButtonTextActiveColor;
    border-color: $helpButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: $helpButtonBg;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
      color: $helpButtonBg;
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
      color: $helpButtonBg;
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: $helpButtonBg;
    border-color: transparent;

    &:enabled:hover {
      background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
      color: $helpButtonBg;
    }

    &:enabled:active {
      background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
      color: $helpButtonBg;
    }
  }
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: $dangerButtonTextColor;
  background: $dangerButtonBg;
  border: $dangerButtonBorder;

  &:enabled:hover {
    background: $dangerButtonHoverBg;
    color: $dangerButtonTextHoverColor;
    border-color: $dangerButtonHoverBorderColor;
  }

  //&:enabled:focus {
  //  box-shadow: $dangerButtonFocusShadow;
  //}

  &:enabled:active {
    background: $dangerButtonActiveBg;
    color: $dangerButtonTextActiveColor;
    border-color: $dangerButtonActiveBorderColor;
  }

  &.p-button-outlined {
    background-color: transparent;
    color: $dangerButtonBg;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
      color: $dangerButtonBg;
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
      color: $dangerButtonBg;
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    background-color: transparent;
    color: $dangerButtonBg;
    border-color: transparent;

    &:enabled:hover {
      background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
      color: $dangerButtonBg;
    }

    &:enabled:active {
      background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
      color: $dangerButtonBg;
    }
  }
}

.gea-link.p-button.p-button-link {
  color: $linkButtonColor;
  background: transparent;
  border: transparent;
  padding: 16px;

  &:enabled:hover {
    background: transparent;
    color: $linkButtonHoverColor;
    border-color: transparent;

    .p-button-label {
      text-decoration: $linkButtonTextHoverDecoration;
    }
  }

  &.p-button-cancel-red {
    color: $dangerButtonBg;
    &:enabled:hover {
      color: $dangerButtonBg;
    }
  }

  &.p-button-primary {
    padding: 16px;
  }

  //&:enabled:focus {
  //  background: transparent;
  //  box-shadow: $linkButtonFocusShadow;
  //  border-color:  transparent;
  //}

  &:enabled:active {
    background: transparent;
    color: $linkButtonColor;
    border-color: transparent;
  }

  &.p-button-outlined-link {
    color: $geaui-primary-black;
    border: 2px solid $geaui-grey-02;

    &:enabled:hover {
      color: $geaui-primary-black;
      background: $geaui-secondary-hover;
      border: 2px solid $geaui-grey-02;
    }

    &:disabled {
      color: $geaui-grey-01;
      border-color: $geaui-grey-03;
      background: $geaui-grey-04;
    }

    &:enabled:active {
      background: $geaui-secondary-pressed;
    }

    &:focus,
    &:focus-visible {
      border-radius: initial;
      border-color: $geaui-focused-outline;
    }
  }
}

// small buttons
.p-button-size-small {
  height: 30px;
}

//button group
geaui-button-group-v2 {
  .gea-button-group-container {
    p-button button, .p-button-sm {
      border-width: 0;
    }

    p-button:first-child button {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    p-button:last-child button {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.gea-button-group-type-grey {
      p-button button {
        color: $geaui-pure-black;
        background: $geaui-grey-05;
      }

      p-button:hover button {
        color: $geaui-pure-black;
        background: $geaui-secondary-hover;
      }

      p-button:active button {
        color: $geaui-pure-black;
        background: $geaui-secondary-pressed;
      }

      p-button.gea-button-group-selected button {
        color: $geaui-pure-white;
        background: $geaui-primary-black;
      }
    }
  }
}
