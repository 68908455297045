@use 'sass:math';

.gea-autocomplete-select-container {
  .p-autocomplete {
    position: relative;
    width: 100%;
    height: 48px;

    p-overlay {
      .p-overlay {
        top: 72px !important;
      }
    }

    .p-autocomplete-loader {
      // as long as empty string is not considered a valid input, the loading will not stop.
      display: none;
      right: nth($inputPadding, 2);
    }

    &.p-autocomplete-dd {
      .p-autocomplete-loader {
        right: nth($inputPadding, 2) + $buttonIconOnlyWidth;
      }
    }

    .p-autocomplete-multiple-container {
      padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
      gap: $inlineSpacing;

      &:not(.p-disabled):hover {
        border-color: $inputHoverBorderColor;
      }

      .p-autocomplete-input-token {
        padding: math.div(nth($inputPadding, 1), 2) 0;

        input {
          font-family: $fontFamily;
          font-size: $fontSize;
          color: $textColor;
          padding: 0;
          margin: 0;
        }
      }

      .p-autocomplete-token {
        padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
        background: $highlightBg;
        color: $highlightTextColor;
        border-radius: $borderRadius;

        .p-autocomplete-token-icon {
          margin-left: $inlineSpacing;
        }
      }
    }
  }

  .dropdown-open {
    .p-autocomplete:after {
      content: '';
      height: 2px;
      width: calc(100% - 2rem);
      top: 60px;
      margin: 0 1rem;
      background: $geaui-primary;
      position: absolute;
    }
  }

  p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    @include invalid-input();
  }

  .p-autocomplete-panel {
    position: absolute;
    left: 0;
    width: round(100%, 1px);
    background: var(--geaui-bright-gray);
    color: $inputListTextColor;
    border: $inputBorder;
    border-top: none;
    border-color: $inputHoverBorderColor;
    border-radius: $borderRadius;

    .p-autocomplete-items {
      padding: 0;

      .p-autocomplete-item {
        margin: $inputListItemMargin;
        padding: 13px 1rem;
        border: $inputListItemBorder;
        color: $inputListItemTextColor;
        background: $inputListItemBg;
        transition: $listItemTransition;
        border-radius: $inputListItemBorderRadius;
        @include gea-font-subline(16);

        &:hover {
          color: var(--geaui-vibrant-blue);
          background: var(--geaui-blue-gray);
        }

        &.p-focus {
          color: var(--geaui-vibrant-blue);
          background: var(--geaui-blue-gray);
        }

        &.p-highlight {
          color: $highlightTextColor;
          background: $highlightBg;
        }
      }

      .p-autocomplete-empty-message {
        padding: 13px 1rem;
        color: $inputListItemTextColor;
        background: $inputListItemBg;
      }

      .p-autocomplete-item-group {
        margin: $submenuHeaderMargin;
        padding: $submenuHeaderPadding;
        color: $submenuHeaderTextColor;
        background: $submenuHeaderBg;
        font-weight: $submenuHeaderFontWeight;
      }
    }
  }

  p-autocomplete.p-autocomplete-clearable {
    .p-inputtext {
      padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
    }

    .p-autocomplete-clear-icon {
      color: $inputIconColor;
      right: nth($inputPadding, 2);
    }
  }

  p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd {
    .p-autocomplete-clear-icon {
      color: $inputIconColor;
      right: $buttonIconOnlyWidth + nth($inputPadding, 2);
    }
  }

  .p-inputtext {
    font-family: $fontFamily;
    font-size: $inputTextFontSize;
    font-weight: 500;
    color: $geaui-primary-black;
    background: $inputBg;
    padding: 0.8rem 1rem;
    padding-right: 70px;
    border: $inputBorder;
    transition: $formElementTransition;
    appearance: none;
    border-radius: $borderRadius;

    &:enabled:hover {
      border-color: $inputHoverBorderColor;
    }

    &:enabled:focus,
    &:focus {
      border: 2px solid $inputHoverBorderColor;
    }

    &.ng-dirty.ng-invalid {
      @include invalid-input();
    }

    &.p-inputtext-sm {
      @include scaledFontSize($inputTextFontSize, $scaleSM);
      @include scaledPadding($inputPadding, $scaleSM);
    }

    &.p-inputtext-lg {
      @include scaledFontSize($inputTextFontSize, $scaleLG);
      @include scaledPadding($inputPadding, $scaleLG);
    }

    &.border-only-bottom {
      border: 0;
      background-color: transparent;
      border-bottom-color: $geaui-primary;
    }

    &:enabled.border-only-bottom,
    &:focus.border-only-bottom {
      border: 0;
      border-bottom: 2px solid $inputHoverBorderColor;
    }
  }
}

.gea-autocomplete-select-container {
  position: relative;
  width: 100%;
  height: 48px;

  span {
    height: 100%;

    input {
      width: 100%;
      height: 100%;
    }

    input::placeholder {
      color: $geaui-primary;
    }
  }

  .autocomplete-select-dropdown-icons {
    position: absolute;
    top: 16px;
    right: 16px;

    &.dropdown-open {
      top: 0;
    }

    &.remove-icon {
      right: 48px;
    }

    :hover {
      cursor: pointer;
    }

    svg {
      pointer-events: none;
    }
  }

  .autocomplete-select-dropdown-icons.icon-disabled-state {
    :hover {
      cursor: default;
    }
  }

  span label {
    height: 20px;
  }

  .custom-disable > input {
    border-color: $geaui-grey-03;
    color: $geaui-grey-01 !important;
    font-family: $fontFamily;
    font-size: 1rem;
    font-weight: 400;
    opacity: 1 !important;

    &:hover {
      border-color: $geaui-grey-05 !important;
    }

    &:focus {
      border-color: $geaui-grey-05 !important;
    }
  }

  .custom-disable > label {
    color: $geaui-grey-01;
  }

  .dropdown-open {
    height: 72px;
    border-bottom: none !important;
  }

  .dropdown-upwards input.dropdown-open {
    border-top: none !important;
    border-bottom: 2px solid $geaui-primary !important;
  }
}

:has(.dropdown-upwards.dropdown-open) body > .p-overlay .p-autocomplete-panel {
  border-top: 2px solid $geaui-primary;
  border-bottom: none;
  top: 1px;
}
