@use 'sass:math';

.p-autocomplete {
  position: relative;
  width: 100%;
  height: 48px;

  .p-autocomplete-loader {
    // as long as empty string is not considered a valid input, the loading will not stop.
    display: none;
    right: nth($inputPadding, 2);
  }

  &.p-autocomplete-dd {
    .p-autocomplete-loader {
      right: nth($inputPadding, 2) + $buttonIconOnlyWidth;
    }
  }

  .p-autocomplete-multiple-container {
    padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
    gap: $inlineSpacing;

    &:not(.p-disabled):hover {
      border-color: $inputHoverBorderColor;
    }

    .p-autocomplete-input-token {
      padding: math.div(nth($inputPadding, 1), 2) 0;

      input {
        font-family: $fontFamily;
        font-size: $fontSize;
        color: $textColor;
        padding: 0;
        margin: 0;
      }
    }

    .p-autocomplete-token {
      padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
      background: $highlightBg;
      color: $highlightTextColor;
      border-radius: $borderRadius;

      .p-autocomplete-token-icon {
        margin-left: $inlineSpacing;
      }
    }
  }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  @include invalid-input();
}

.p-autocomplete-panel {
  position: absolute;
  left: 0;
  width: 100%;
  background: var(--geaui-bright-gray);
  color: $inputListTextColor;
  border: $inputBorder;
  border-top: none;
  border-bottom: none;
  border-color: $inputHoverBorderColor;
  border-radius: $borderRadius;

  .p-autocomplete-items {
    padding: 0;

    .p-autocomplete-item {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background: $inputListItemBg;
      transition: $listItemTransition;
      border-radius: $inputListItemBorderRadius;
      @include gea-font-subline(16);

      &:hover {
        color: var(--geaui-vibrant-blue);
        background: var(--geaui-blue-gray);
      }

      &.p-highlight {
        color: $highlightTextColor;
        background: $highlightBg;
      }
    }

    .p-autocomplete-empty-message {
      padding: $inputListItemPadding;
      color: $inputListItemTextColor;
      background: $inputListItemBg;
    }

    .p-autocomplete-item-group {
      margin: $submenuHeaderMargin;
      padding: $submenuHeaderPadding;
      color: $submenuHeaderTextColor;
      background: $submenuHeaderBg;
      font-weight: $submenuHeaderFontWeight;
    }
  }
}

p-autocomplete.p-autocomplete-clearable {
  .p-inputtext {
    padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
  }

  .p-autocomplete-clear-icon {
    color: $inputIconColor;
    right: nth($inputPadding, 2);
  }
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd {
  .p-autocomplete-clear-icon {
    color: $inputIconColor;
    right: $buttonIconOnlyWidth + nth($inputPadding, 2);
  }
}

.p-inputtext {
  font-family: $fontFamily;
  font-size: $inputTextFontSize;
  font-weight: 400;
  color: $inputTextColor;
  background: $inputBg;
  padding: $inputPadding;
  border: $inputBorder;
  transition: $formElementTransition;
  appearance: none;
  border-radius: $borderRadius;

  &:enabled:hover {
    border-color: $inputHoverBorderColor;
  }

  &:enabled:focus,
  &:focus {
    border: 2px solid $inputHoverBorderColor;
  }

  &.ng-dirty.ng-invalid {
    @include invalid-input();
  }

  &.p-inputtext-sm {
    @include scaledFontSize($inputTextFontSize, $scaleSM);
    @include scaledPadding($inputPadding, $scaleSM);
  }

  &.p-inputtext-lg {
    @include scaledFontSize($inputTextFontSize, $scaleLG);
    @include scaledPadding($inputPadding, $scaleLG);
  }

  &.border-only-bottom {
    border: 0;
    background-color: transparent;
    border-bottom-color: $geaui-primary;
  }

  &:enabled.border-only-bottom,
  &:focus.border-only-bottom {
    border: 0;
    border-bottom: 2px solid $inputHoverBorderColor;
  }
}

.gea-search-bar-container {
  position: relative;
  width: 100%;
  height: 48px;

  span {
    height: 100%;

    input {
      width: 100%;
      height: 100%;
      padding-right: 130px;
    }
  }

  .search-bar-button {
    position: absolute;
    top: 8px;
    right: 10px;

    button {
      width: 72px;
      height: 32px;
      padding: 8px 22px;
      justify-content: center;
    }
  }

  .search-bar-button-reduced {
    position: absolute;
    top: 16px;
    right: 22px;
  }

  .search-bar-button-remove-reduced {
    position: absolute;
    top: 5px;
    right: 16px;

    .with-icon .p-button {
      padding: 6px 8px;
    }
  }

  .search-bar-button-remove-prominent {
    position: absolute;
    top: 5px;
    right: 85px;

    .with-icon .p-button {
      padding: 6px 8px;
    }
  }

  label {
    position: absolute;
    top: -24px !important;
    left: 2px !important;
  }
}

.custom-disable > input {
  border: 2px solid $geaui-grey-03;
  color: $geaui-grey-01 !important;
  font-family: $fontFamily;
  font-size: 1rem;
  font-weight: 400;

  &:hover {
    border-color: $geaui-grey-05 !important;
    cursor: default;
  }

  &:focus {
    border-color: $geaui-grey-05 !important;
  }
}

.custom-disable > label {
  color: $geaui-grey-01;
  opacity: 0.8;
}

.gea-search-bar-error-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  .gea-search-bar-error-icon {
    width: 16px;
  }

  .gea-search-bar-error-text {
    @include gea-font-heading(14);
    color: $geaui-tertiary-red-80;
  }
}

.gea-search-bar-container .p-autocomplete-panel .p-autocomplete .p-inputtext {
  @include gea-font-heading(14);
}

.gea-search-bar-container p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: $geaui-grey-02;
}

.gea-search-bar-container .p-autocomplete-panel::-webkit-scrollbar {
  width: 15px;
}

.gea-search-bar-container .p-autocomplete-panel::-webkit-scrollbar-track {
  background: $geaui-grey-03;
}

.gea-search-bar-container .p-autocomplete-panel::-webkit-scrollbar-thumb {
  color: $geaui-primary;
  border-top: 2px solid $geaui-grey-03;
  border-right: 5px solid $geaui-grey-03;
  border-left: 5px solid $geaui-grey-03;
}

.gea-search-bar-container .padding-right-60 span input {
  padding-right: 60px;
}

.gea-search-bar-container div input {
  padding-right: 46px;
}

.gea-search-bar-container .has-prominent-button div input {
  padding-right: 115px;
}

.gea-search-bar-container .p-focus .p-inputtext:enabled:focus,
.gea-search-bar-container .p-focus .p-inputtext:focus {
  border: 2px solid $geaui-primary;
}
