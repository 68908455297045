.p-paginator {
    background: $paginatorBg;
    color: $paginatorTextColor;
    border: $paginatorBorder;
    border-width: $paginatorBorderWidth;
    padding: $paginatorPadding;
    border-radius: $borderRadius;

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
        background-color: $geaui-button-default;
        border: $paginatorElementBorder;
        color: $paginatorElementIconColor;
        min-width: $paginatorElementWidth;
        height: $paginatorElementHeight;
        margin: $paginatorElementMargin;
        margin-left: 8px;
        margin-right: 8px;
        transition: $listItemTransition;
        border-radius: 50px;

        &:not(.p-disabled):not(.p-highlight):hover {
            background: $buttonHoverBg;
            border-color: $paginatorElementHoverBorderColor;
            color: $paginatorElementIconHoverColor;
        }

        .p-icon-wrapper {
          color: white;
        }
    }

    .p-paginator-first {
        background-color: white;
        .p-icon-wrapper {
        	color: $geaui-primary;
        }
        &:not(.p-disabled):not(.p-highlight):hover {
            background: $paginatorElementHoverBg;
            border-color: $paginatorElementHoverBorderColor;
            color: $paginatorElementIconHoverColor;
        }
    }

    .p-paginator-last {
        background-color: white;
        .p-icon-wrapper {
        	color: $geaui-primary;
        }
        &:not(.p-disabled):not(.p-highlight):hover {
            background: $paginatorElementHoverBg;
            border-color: $paginatorElementHoverBorderColor;
            color: $paginatorElementIconHoverColor;
        }
    }

    .p-dropdown {
        margin-left: $inlineSpacing;
        margin-right: $inlineSpacing;
        height: $paginatorElementHeight;

        .p-dropdown-label {
            padding-right: 0;
        }
    }

    .p-paginator-page-input {
        margin-left: $inlineSpacing;
        margin-right: $inlineSpacing;

        .p-inputtext {
            max-width: $paginatorElementWidth;
        }
    }

    .p-paginator-current {
        background-color: $paginatorElementBg;
        border: $paginatorElementBorder;
        color: $paginatorElementIconColor;
        min-width: $paginatorElementWidth;
        height: $paginatorElementHeight;
        margin: $paginatorElementMargin;
        margin-right: 24px;
        padding: 0 $inlineSpacing;
        cursor: auto;
    }

    .p-paginator-pages {
        .p-paginator-page {
            border: $paginatorElementBorder;
            color: $geaui-primary-black;
            min-width: $paginatorElementWidth;
            height: $paginatorElementHeight;
            margin: $paginatorElementMargin;
            transition: $listItemTransition;
            border-radius: $paginatorElementBorderRadius;

            &.p-highlight {
              border-color: $geaui-primary;
              color: $geaui-primary;
              border-bottom: 2px solid;
            }

            &:not(.p-highlight):hover {
                background: $paginatorElementHoverBg;
                border-color: $paginatorElementHoverBorderColor;
                color: $paginatorElementIconHoverColor;
            }
        }
    }
}
