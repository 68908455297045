@use 'sass:math';
@import "../../../../../styles/variables";


.p-multiselect {
  background: $inputBg;
  border: $inputBorder;
  transition: $formElementTransition;
  border-radius: $borderRadius;

  &:not(.p-disabled):hover {
    border-color: $inputHoverBorderColor;
  }

  &:not(.p-disabled).p-focus {
    //@include focused-input();
    border-color: $geaui-primary;
  }

  .p-multiselect-label {
    padding: $inputPadding;
    transition: $formElementTransition;

    &.p-placeholder {
      color: $inputPlaceholderTextColor;
    }
  }

  &.p-multiselect-chip {
    .p-multiselect-label {
      gap: $inlineSpacing;
    }

    .p-multiselect-token {
      padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
      background: $highlightBg;
      color: $highlightTextColor;
      border-radius: $borderRadius;

      .p-multiselect-token-icon {
        margin-left: $inlineSpacing;
      }
    }
  }

  .p-multiselect-trigger {
    background: transparent;
    color: $inputIconColor;
    width: $inputGroupAddOnMinWidth;
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }
}

.p-inputwrapper-filled {
  .p-multiselect {
    &.p-multiselect-chip {
      .p-multiselect-label {
        padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
      }
    }
  }
}

.p-multiselect-panel {
  background: $inputOverlayBg;
  color: $inputListTextColor;
  border: $inputOverlayBorder;
  border-radius: $borderRadius;
  box-shadow: $inputOverlayShadow;

  .p-multiselect-header {
    padding: $inputListHeaderPadding;
    border-bottom: $inputListHeaderBorder;
    color: $inputListHeaderTextColor;
    margin: $inputListHeaderMargin;
    border-top-right-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    position: relative;

    .p-multiselect-filter {
      border-right: none;
      border-left: none;
      border-top: none;
      border-bottom: 2px solid $geaui-primary;

      &::placeholder {
        color: $geaui-primary;
        font-weight: 500;
      }
    }

    .p-icon-wrapper > .p-icon {
      display: none
    }

    .p-multiselect-filter-container {
      position: relative;
      width: 100%;

      .p-inputtext {
        padding-right: 50px;
        padding-left: 0;
      }

      .gea-multiselect-search-icon {
        position: absolute;
        top: 15px;
        right: 0px;
        visibility: hidden;
      }

      .gea-multiselect-close-icon {
        position: absolute;
        cursor: pointer;
        top: 15px;
        right: 0px;
        transition: transform 0.1s;
        transition-delay: 0.1s; // delay is needed to fix a bug, because close icon moves to fast when is clicked
      }

      .p-inputtext:enabled:focus {
        border-right: none;
        border-left: none;
        border-top: none;
      }

      .p-inputtext:enabled:focus ~ .gea-multiselect-close-icon {
        transform: translateX(-30px);
        transition: transform 0.1s;
      }

      .p-inputtext:enabled:focus ~ .gea-multiselect-search-icon {
        visibility: visible;
      }
    }

    .p-checkbox {
      margin-right: $inlineSpacing;
      display: none;
    }

    .p-multiselect-close {
      margin-left: $inlineSpacing;
      @include action-icon();
      display: none;
    }
  }

  .p-multiselect-items {
    padding: $inputListPadding;
    position: relative;
    background-color: $geaui-grey-05;


    .p-multiselect-item {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background: $inputListItemBg;
      transition: $listItemTransition;
      border-radius: $inputListItemBorderRadius;

      &:not(.p-highlight):not(.p-disabled):hover {
        color: $inputListItemTextHoverColor;
        background: $inputListItemHoverBg;
      }

      &:focus {
        box-shadow: none;
      }

      .p-checkbox {
        margin-right: $inlineSpacing;
      }
    }


    .p-multiselect-item-group {
      margin: $submenuHeaderMargin;
      padding: $submenuHeaderPadding;
      color: $submenuHeaderTextColor;
      background: $submenuHeaderBg;
      font-weight: $submenuHeaderFontWeight;
    }

    .p-multiselect-empty-message {
      padding: $inputListItemPadding;
      color: $inputListItemTextColor;
      background: $inputListItemBg;
    }
  }
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  @include invalid-input();
}

.p-input-filled {
  .p-multiselect {
    background: $inputFilledBg;

    &:not(.p-disabled):hover {
      background-color: $inputFilledHoverBg;
    }

    &:not(.p-disabled).p-focus {
      background-color: $geaui-focused-outline;
    }
  }
}

p-multiselect.p-multiselect-clearable {
  .p-multiselect-label-container {
    padding-right: nth($inputPadding, 2) + $primeIconFontSize;
  }

  .p-multiselect-clear-icon {
    color: $inputIconColor;
    right: $inputGroupAddOnMinWidth;
  }
}

/*   Custom Css Styles    */

.gea-multiselect-field .p-multiselect {
  position: relative;
  width: round(100%, 1px);
  height: 48px;
}

.gea-multiselect-field .isDropdownOpen .p-multiselect {
  border-top-color: $geaui-primary;
  border-right-color: $geaui-primary;
  border-left-color: $geaui-primary;
  border-bottom-color: transparent;
}

.gea-multiselect-field .p-multiselect .p-multiselect-label.p-placeholder {
  color: $geaui-pure-black;
  padding-left: 16px;
}

.gea-multiselect-field {

  .p-multiselect-label-container {
    align-items: center;
  }

  .p-multiselect-label {
    color: $geaui-pure-black;
    padding: 10px 15px;
  }
}

.gea-multiselect-field.p-float-label > label {
  font-family: $fontFamily;
  font-size: 16px;
  font-weight: 400;
  top: 49%;
  height: 20px;
}

.gea-multiselect-field .p-multiselect.p-inputwrapper-focus {
  &:not(.p-disabled) {
    border: 2px solid $geaui-primary;
  }
}

.gea-multiselect-field .isDropdownOpen .p-multiselect.p-inputwrapper-focus {
  &:not(.p-disabled) {
    border-bottom: none;
  }
}


.gea-multiselect-field .p-multiselect.p-component.p-disabled {
  border-color: $geaui-grey-05;

  .p-placeholder {
    color: $geaui-grey-01;
  }
}

.gea-multiselect-label-disabled {
  color: $geaui-grey-01 !important;
  opacity: 0.8;
}

.gea-multiselect-field .p-multiselect-panel {
  overflow: auto;
  position: relative;
  border-left: 2px solid $geaui-primary;
  border-right: 2px solid $geaui-primary;
  border-bottom: 2px solid $geaui-primary;
  top: -4px;
  left: -2px;
  width: calc(100% + 4px);
}

.gea-multiselect-field .p-multiselect-panel .p-multiselect-items {
  padding: 0;
}

/*
Necessary because the multiselect panel is not a child of .gea-multiselect-field.
Therefore, you have to select all the way down through the
body to address it as specifically as possible
 */
body > .p-overlay .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 10px 10px 15px 16px;
  border: 0 none;
  color: $geaui-pure-black;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  font-size: 16px;
  font-weight: 400;

  &:not(.p-disabled):hover {
    color: $geaui-primary;
    background-color: $geaui-vibrant-blue-100
  }

  &:focus {
    box-shadow: none;
    font-weight: 500;
  }

  &.p-focus {
    color: $geaui-vibrant-blue;
    background-color: $geaui-vibrant-blue-100;

    &:not(.p-disabled):hover {
      color: $geaui-vibrant-blue;
      background-color: $geaui-vibrant-blue-100;
    }
  }


  &.p-highlight {
    font-size: 16px;
    font-weight: 500;
    color: $geaui-primary;
    background: $inputListItemSelected;

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
      border-color: #4F4FCD;
      background: $inputListItemSelected;
      color: #FFFFFF;
    }

    &:not(.p-disabled):hover {
      color: $geaui-primary;
      background: $inputListItemSelected;
    }
  }

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
}

.gea-error-container-multiselect {
  position: relative;
  display: flex;

  > p {
    position: absolute;
    top: 5px;
    left: 27px;
    font-size: 14px;
    margin: 0;
    color: $geaui-tertiary-red-80;
  }

  > .gea-error-icon-position {
    position: absolute;
    top: 5px;
    left: 2px;
  }
}

.gea-multiselect-field.p-float-label > input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -14px !important;
  font-size: 14px;
  left: -7px;
}

.gea-always-float-label > label {
  top: -14px !important;
  left: -7px !important;
  font-size: 14px !important;
}

.gea-multiselect-field .p-multiselect-items-wrapper::-webkit-scrollbar {
  width: 15px;
}

.gea-multiselect-field .p-multiselect-items-wrapper::-webkit-scrollbar-track {
  background: $geaui-grey-05;
}

.gea-multiselect-field .p-multiselect-items-wrapper::-webkit-scrollbar-thumb {
  color: $geaui-primary;
  border-top: 2px solid $geaui-grey-05;
  border-right: 5px solid $geaui-grey-05;
  border-left: 5px solid $geaui-grey-05;
}

.gea-multiselect-footer {
  justify-content: space-between;
  display: flex;

  p {
    margin-top: 20px;
    margin-left: 15px;
    color: $geaui-primary;
  }

  button {
    margin: 10px 15px;
  }
}

body > .p-overlay .p-multiselect-panel .p-multiselect-header {
  display: none;
}

.gea-multiselect-field :not(.gea-multiselect-searchbar) .p-multiselect-header, :has(.gea-multiselect-field .p-inputwrapper-focus.gea-multiselect-searchbar) body > .p-overlay .p-multiselect-panel .p-multiselect-header {
  display: flex;
}

.gea-multiselect-footer-clear {
  font-size: 16px;
  font-weight: 500;
}

.gea-multiselect-field.p-float-label > .gea-multiselect-font-weight-500 {
  font-weight: 500;
}
