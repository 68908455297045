geaui-table-v2 {
  thead tr th .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-header {
      display: flex;
      align-items: center;
      overflow: hidden;
    }
    .header-text {
      margin-right: 10px;
      font-weight: 500;
      font-size: 16px;
      color: $geaui-primary-black;
    }
    .sort-icon-container {
      margin: 0 8px;
    }
    .filter-icon-container {
      margin: -10px;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: calc(16px + 2rem);
      height: calc(16px + 1rem);
      border-radius: 2rem;
    }
    .filter-icon {
      position: relative;

      &.filter-active::after {
        content: '';
        position: absolute;
        top: -4px;
        right: -4px;
        width: 8px;
        height: 8px;
        background-color: $geaui-primary;
        border-radius: 50%;
        z-index: 1;
      }
    }
    geaui-icon-v2 {
      path {
        stroke: $geaui-pure-white;
      }
      &.isSorted path {
        stroke: $geaui-primary-black;
      }
      &.asc {
        path:nth-child(2) {
          stroke: $geaui-grey-02;
        }
      }
      &.desc {
        path:nth-child(1) {
          stroke: $geaui-grey-02;
        }
      }
    }
  }

  .p-datatable-resizable-table > .p-datatable-thead > tr > th.p-resizable-column:not(.p-frozen-column) :hover {
    geaui-icon-v2 {
      path {
        stroke: $geaui-primary-black;
      }
    }
  }

  .sticky-left {
    border-right: 2px solid $geaui-grey-03 !important;
  }

  .sticky-right {
    border-left: 2px solid $geaui-grey-03 !important;
  }

  th:first-child .filter-container {
    left: 0;
    right: unset;
  }

  .p-datatable-flex-scrollable {
    min-height: 300px;
  }

  .no-data .p-datatable-flex-scrollable {
    min-height: 0;
    height: auto;
    .p-datatable-wrapper {
      overflow: visible;
    }
  }

  .geaui-table-loaded .p-datatable .p-datatable-wrapper .p-datatable-tbody tr:hover {
    background-color: $geaui-secondary-hover;
  }

  .p-datatable-resizable-table > .p-datatable-tbody > tr > td {
    overflow: visible;
    &:has(div.index-up) {
      z-index: 2;
    }
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }

  .highlight {
    box-shadow:
      inset 8px 0 0 $geaui-tertiary-green-60,
      inset -8px 0 0 $geaui-tertiary-green-60;
  }

  .resizeable:after {
    content: '';
    position: absolute;
    right: 0;
    top: 7px;
    height: 80%;
    border-right: 2px solid $geaui-grey-03;
  }

  .no-data-box {
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    geaui-icon-v2 {
      width: 139px;
      height: 178px;
    }
    .no-data-title {
      margin-top: 96px;
      font-weight: 500;
      font-size: 24px;
      line-height: 27.6px;
      color: #1d1d1d;
      margin-bottom: 44px;
    }
  }

  .no-data-box-custom {
    height: 100%;
  }

  .no-data-container {
    height: calc(100% - 62px);
    margin-top: 8px;
    width: 100%;
  }

  .content-body {
    min-height: calc(200vh) !important;
  }

  .p-paginator-bottom {
    padding: 1rem;
  }

  .p-paginator-right-content {
    margin-left: 24px;
    display: flex;
    align-items: center;
  }

  .p-paginator .p-dropdown {
    height: 40px;
    margin-left: 8px;
    width: 68px;
  }

  .switch-landscape-overlay {
    display: none;
  }

  .switch-landscape-message {
    display: none;
  }

  @media screen and (orientation: portrait) and (max-width: $gea-tablet-portrait - 1) {
    .geaui-table-blurred {
      filter: blur(8px) !important;
      -webkit-filter: blur(8px) !important;
    }

    .switch-landscape-overlay {
      display: block !important;
      width: 100%;
      z-index: 1;
      position: absolute;
      top: 60px;
      bottom: 75px;
      left: 0;
      background-image: url(../../../../../svgs/switch-landscape.svg);
      background-position: center;
      background-repeat: no-repeat;
    }

    .switch-landscape-message {
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-top: 90px;
      height: 100%;
      color: $geaui-primary-black;
      font-size: 16px;
    }

    .title-blurred {
      filter: blur(8px) !important;
      -webkit-filter: blur(8px) !important;
    }
  }

  .p-datatable-striped {
    &:not(.rowspan) {
      .p-datatable-tbody {
        > tr:nth-child(even) {
          background: $tableBodyRowEvenBg;

          &.p-highlight {
            background: $highlightBg;
            color: $highlightTextColor;

            .p-row-toggler {
              color: $highlightTextColor;

              &:hover {
                color: $highlightTextColor;
              }
            }
          }
        }
      }
    }

    &.rowspan {
      .p-datatable-tbody {
        > tr.odd-striping-color {
          background: $tableBodyRowEvenBg;

          &.p-highlight {
            background: $highlightBg;
            color: $highlightTextColor;

            .p-row-toggler {
              color: $highlightTextColor;

              &:hover {
                color: $highlightTextColor;
              }
            }
          }
        }
      }
    }
  }

  .rowspan {
    .p-datatable-tbody {
      > tr {
        > td {
          padding: 10px 16px;
        }
      }
    }
  }
}

.p-datatable {
  thead.p-datatable-thead tr th {
    overflow: unset;
    background-color: white;
  }

  .p-paginator-top {
    border-width: $tableTopPaginatorBorderWidth;
    border-radius: 0;
  }

  .p-datatable-header {
    background: $tableHeaderBg;
    color: $tableHeaderTextColor;
    border: $tableHeaderBorder;
    border-width: $tableHeaderBorderWidth;
    padding: $tableHeaderPadding;
    font-weight: $tableHeaderFontWeight;
  }

  .p-datatable-footer {
    background: $tableFooterBg;
    color: $tableFooterTextColor;
    border: $tableFooterBorder;
    border-width: $tableFooterBorderWidth;
    padding: $tableFooterPadding;
    font-weight: $tableFooterFontWeight;
  }

  .p-datatable-thead > tr > th {
    text-align: $tableCellContentAlignment;
    padding: $tableHeaderCellPadding;
    border: $tableHeaderCellBorder;
    border-top: none;
    border-width: $tableHeaderCellBorderWidth;
    font-weight: $tableHeaderCellFontWeight;
    font-size: 16px;
    color: $tableHeaderCellTextColor;
    transition: $listItemTransition;
  }

  .p-datatable-tfoot > tr > td {
    text-align: $tableCellContentAlignment;
    padding: $tableFooterCellPadding;
    border: $tableFooterCellBorder;
    border-width: $tableFooterCellBorderWidth;
    font-weight: $tableFooterCellFontWeight;
    color: $tableFooterCellTextColor;
    background: $tableFooterCellBg;
  }

  .p-sortable-column {
    .p-sortable-column-icon {
      color: $tableHeaderCellIconColor;
      margin-left: $inlineSpacing;
    }

    .p-sortable-column-badge {
      border-radius: 50%;
      height: $tableSortableColumnBadgeSize;
      min-width: $tableSortableColumnBadgeSize;
      line-height: $tableSortableColumnBadgeSize;
      color: $highlightTextColor;
      background: $highlightBg;
      margin-left: $inlineSpacing;
    }

    &:not(.p-highlight):hover {
      .p-sortable-column-icon {
        color: $tableHeaderCellIconHoverColor;
      }
    }

    &.p-highlight {
      .p-sortable-column-icon {
        color: $tableHeaderCellHighlightTextColor;
      }

      &:hover {
        .p-sortable-column-icon {
          color: $tableHeaderCellHighlightTextColor;
        }
      }
    }

    &:focus {
      outline: 0 none;
    }
  }

  .p-datatable-tbody {
    > tr {
      background: $tableBodyRowBg;
      color: $tableBodyRowTextColor;
      transition: $listItemTransition;

      &.clickable {
        cursor: pointer;
      }

      > td {
        text-align: $tableCellContentAlignment;
        padding: $tableBodyCellPadding;
        font-size: 16px;
        color: $geaui-primary-black;

        .p-row-toggler,
        .p-row-editor-init,
        .p-row-editor-save,
        .p-row-editor-cancel {
          @include action-icon();
        }

        .p-row-editor-save {
          margin-right: $inlineSpacing;
        }
      }

      &:focus {
        outline: 0.15rem solid $focusOutlineColor;
        outline-offset: 0.15rem;
      }

      &.p-highlight {
        background: $highlightBg;
        color: $highlightTextColor;
      }

      &.p-datatable-dragpoint-top > td {
        box-shadow: inset 0 2px 0 0 $highlightBg;
      }

      &.p-datatable-dragpoint-bottom > td {
        box-shadow: inset 0 -2px 0 0 $highlightBg;
      }
    }
  }

  &.p-datatable-hoverable-rows {
    .p-datatable-tbody > tr:not(.p-highlight):hover {
      background: $tableBodyRowHoverBg;
      color: $tableBodyRowTextHoverColor;
    }
  }

  .p-column-resizer-helper {
    background: $tableResizerHelperBg;
  }

  .p-datatable-scrollable-header,
  .p-datatable-scrollable-footer {
    background: $panelHeaderBg;
  }

  .p-datatable-loading-icon {
    font-size: $loadingIconFontSize;
  }

  &.p-datatable-gridlines {
    .p-datatable-header {
      border-width: 1px 1px 0 1px;
    }

    .p-datatable-footer {
      border-width: 0 1px 1px 1px;
    }

    .p-paginator-top {
      border-width: 0 1px 0 1px;
    }

    .p-paginator-bottom {
      border-width: 0 1px 1px 1px;
    }

    .p-datatable-thead {
      > tr {
        > th {
          border-width: 1px 0 1px 1px;

          &:last-child {
            border-width: 1px;
          }
        }
      }
    }

    .p-datatable-tbody {
      > tr {
        > td {
          border-width: 1px 0 0 1px;

          &:last-child {
            border-width: 1px 1px 0 1px;
          }
        }

        &:last-child {
          > td {
            border-width: 1px 0 1px 1px;

            &:last-child {
              border-width: 1px;
            }
          }
        }
      }
    }

    .p-datatable-tfoot {
      > tr {
        > td {
          border-width: 1px 0 1px 1px;

          &:last-child {
            border-width: 1px 1px 1px 1px;
          }
        }
      }
    }

    .p-datatable-thead + .p-datatable-tfoot {
      > tr {
        > td {
          border-width: 0 0 1px 1px;

          &:last-child {
            border-width: 0 1px 1px 1px;
          }
        }
      }
    }

    &:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody {
      > tr {
        > td {
          border-width: 0 0 1px 1px;

          &:last-child {
            border-width: 0 1px 1px 1px;
          }
        }
      }
    }

    &:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody {
      > tr {
        &:last-child {
          > td {
            border-width: 0 0 0 1px;

            &:last-child {
              border-width: 0 1px 0 1px;
            }
          }
        }
      }
    }
  }

  &.p-datatable-sm {
    .p-datatable-header {
      @include scaledPadding($tableHeaderPadding, $tableScaleSM);
    }

    .p-datatable-thead > tr > th {
      @include scaledPadding($tableHeaderCellPadding, $tableScaleSM);
    }

    .p-datatable-tbody > tr > td {
      @include scaledPadding($tableBodyCellPadding, $tableScaleSM);
    }

    .p-datatable-tfoot > tr > td {
      @include scaledPadding($tableFooterPadding, $tableScaleSM);
    }

    .p-datatable-footer {
      @include scaledPadding($tableFooterPadding, $tableScaleSM);
    }
  }

  &.p-datatable-lg {
    .p-datatable-header {
      @include scaledPadding($tableHeaderPadding, $tableScaleLG);
    }

    .p-datatable-thead > tr > th {
      @include scaledPadding($tableHeaderCellPadding, $tableScaleLG);
    }

    .p-datatable-tbody > tr > td {
      @include scaledPadding($tableBodyCellPadding, $tableScaleLG);
    }

    .p-datatable-tfoot > tr > td {
      @include scaledPadding($tableFooterPadding, $tableScaleLG);
    }

    .p-datatable-footer {
      @include scaledPadding($tableFooterPadding, $tableScaleLG);
    }
  }
}
